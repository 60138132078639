var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('br'),_c('v-card',{staticStyle:{"margin-left":"1%","margin-right":"1%","margin-top":"2%","font-family":"'Montserrat' sans-serif","border-radius":"10px 10px 10px 10px"},attrs:{"max-width":"98%","max-height":"15000"}},[_c('div',[_c('v-card',[_c('v-card-title',{staticStyle:{"font-size":"17px"}},[_c('v-spacer'),_c('v-spacer'),_c('b',[_vm._v("Reservaciones")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"single-select":_vm.singleSelect,"search":_vm.search,"item-key":"clave","show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-switch',{staticClass:"pa-3",attrs:{"label":"Single select"},model:{value:(_vm.singleSelect),callback:function ($$v) {_vm.singleSelect=$$v},expression:"singleSelect"}})]},proxy:true},{key:"item.clave",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":"/detalles"}},[_c('br'),_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"#818181","font-size":"14px","border":"1px solid #818181","border-radius":"100px"},attrs:{"small":""}},[_vm._v(" fas fa-ellipsis-h ")]),_c('span',[_vm._v(_vm._s(item.clave))]),_c('br'),_c('br')],1)]}},{key:"item.pago_op",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",staticStyle:{"font-size":"13px"},attrs:{"small":"","color":_vm.getColoriCON(item.pago_op)}},[_vm._v(" fas fa-dollar-sign ")]),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.pago_op))])]}},{key:"item.pago_ag",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(item.pago_ag))])]}},{key:"item.hotel",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(item.hotel))])]}},{key:"item.agenciatitular",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(item.agenciatitular))])]}},{key:"item.brokers",fn:function(ref){
var item = ref.item;
return [(item.img)?_c('img',{staticStyle:{"width":"18px"},attrs:{"src":item.img}}):_vm._e(),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(item.brokers))])]}},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.formatNumber(item.precio)))])]}},{key:"item.fecha_llegada",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.reformatDate(item.fecha_llegada)))])]}},{key:"item.fecha_limite",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.reformatDate(item.fecha_llegada)))])]}},{key:"item.fecha_reservacion",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.reformatDate(item.fecha_llegada)))])]}},{key:"item.calories",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"font-size":"13px"},attrs:{"color":_vm.getColor(item.calories),"dark":""}},[_vm._v(" "+_vm._s(item.calories)+" ")])]}},{key:"item.titular",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.titular)+" "),_c('br'),_vm._v(" "),_c('a',{attrs:{"href":("mailto:" + (item.correo))}},[_vm._v(" "+_vm._s(item.correo)+" ")])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)]),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }